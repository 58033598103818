import React from 'react'
import offers from "../assets/offer.png"
export const Offers = () => {
  return (
    <div className='bookslotcontainer'>
        <div style={{margin:"3% auto"}}>
            <img src={offers} alt="noimage"/>
        </div>
    </div>
  )
}
